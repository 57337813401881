.text-muted {
  color: $color-gray-7 !important;
}

.text-underlined {
  text-decoration: underline;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-small {
  font-size: 0.867rem !important;
}

.text-smaller {
  font-size: 0.8rem !important;
}

.text-medium {
  font-size: 1.1rem !important;
}

.text-regular {
  font-size: 1.2rem !important;
}

.text-big {
  font-size: 1.4rem !important;
}

.text-xl {
  font-size: 2.3rem !important;
}

.text-semi-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.no-border {
  border: none !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.padding-bottom {
  &--one {
    padding-bottom: 1rem !important;
  }
  &--two {
    padding-bottom: 2rem !important;
  }
}

.padding-top {
  &--one {
    padding-top: 1rem !important;
  }
  &--two {
    padding-top: 2rem !important;
  }
  &--twentieth {
    padding-top: 0.2rem !important;
  }
}

.margin-left {
  &--one {
    margin-left: 1rem !important;
  }

  &--two {
    margin-left: 2rem !important;
  }
}

.margin-top {
  &--half {
    margin-top: 0.5rem !important;
  }

  &--one {
    margin-top: 1rem !important;
  }

  &--one-and-half {
    margin-top: 1.5rem !important;
  }

  &--two {
    margin-top: 2rem !important;
  }

  &--two-and-half {
    margin-top: 2.5rem !important;
  }

  &--three {
    margin-top: 3rem !important;
  }
}

.margin-bottom {
  &--none {
    margin-bottom: 0 !important;
  }

  &--one {
    margin-bottom: 1rem !important;
  }

  &--one-and-half {
    margin-bottom: 1.5rem !important;
  }

  &--two {
    margin-bottom: 2rem !important;
  }

  &--two-and-half {
    margin-bottom: 2.5rem !important;
  }

  &--three {
    margin-bottom: 3rem !important;
  }

  &--four {
    margin-bottom: 4rem !important;
  }
}

.margin-right {
  &--one {
    margin-right: 1rem !important;
  }

  &--two {
    margin-right: 2rem !important;
  }
}

.margin-x-auto {
  margin: 0 auto !important;
}

[hidden],
.hidden {
  display: none !important;
}

.flex-align {
  &--left {
    display: flex;
    margin-left: auto;
    align-self: flex-start;
  }

  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--right {
    display: flex;
    margin-left: auto;
    align-self: flex-end;
  }
}

.required-fail {
  border-color: $color-red !important;
}

.form-disclaimer {
  font-size: 0.75rem;
  @include font('body', 'normal');
  color: $color-gray-8;
  margin-bottom: 1rem;
}

.overflow-hidden {
  overflow: hidden !important;
}

.half-width {
  width: 50%;
}
