.bg {
    &-blue {
        background-color: $color-blue-3;
        &--light {
            background-color: $color-blue-1;
        }
        &--lighter {
            background-color: $color-blue-2;
        }
    }
}
