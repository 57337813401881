@import '../../styles/utils/variables.scss';

.tags-modal {
  min-width: 20.3125rem;
  min-height: 5.86rem;
  display: none;
  //box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  position: absolute;
  top: 110%;
  left: $sideNavigationWidth + 0.1125rem;

  &--wrapper {
    background: $color-white;
    position: relative;
    padding: 0.6875rem 1.1875rem 0.9375rem;
    border-radius: 0.3125rem;
    z-index: 100;
  }

  &--open {
    display: block;

    &:before {
      background-color: rgba(0, 0, 0, 0.6);
      position: fixed;
      width: 200vw;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: 10;
    }
  }

  p {
    @include font('body', 'normal');
    font-size: 0.5625rem;
    letter-spacing: 0.02em;
    color: $color-black-light;
    margin-bottom: 0.75rem;
  }

  button {
    &:not(:only-of-type):not(:last-of-type) {
      margin-right: 0.21875rem;
    }
  }
}
