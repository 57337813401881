@import './variables.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  min-height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html ::-moz-selection,
body ::-moz-selection {
  background: #F0E7E0;
}
html ::selection,
body ::selection {
  background: #F0E7E0;
}

template {
  display: none;
}

main {
  display: block;
  width: 100%;
}

footer {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  font-weight: 400;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3.2rem;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.2rem;
}

a {
  color: inherit;
  text-decoration: inherit;
}

small {
  display: inline-block;
  font-size: 0.867rem;
}

button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
button:hover, button:active, button:focus {
  outline: 0;
}

input[type=checkbox] {
  margin: 0;
}

hr {
  height: 0.2rem;
  margin: 1.53rem 0;
  border: none;
  background-color: transparent;
}

table {
  width: 100%;
  height: auto;
}

p {
  line-height: 1.6rem;
}

li {
  list-style: none;
}

figure {
  background-repeat: no-repeat;
  background-position: center center;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #4062AE;
  opacity: 1;
}

:focus {
  outline: none;
}

:required {
  box-shadow: none;
}

::-moz-focus-inner {
  border: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
