@import '../../styles/utils/variables';

.interests-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--item {
    background-color: #FFF;
    border: 1px solid rgba(0,0,0,0.12);
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    min-width: 300px;
    margin-bottom: 8px;
    border-radius: 150px;
    padding-left: 40px;
    margin-right: 8px;

    .checkmark {
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      background-color: #ECECEC;
      width: 40px;
      height: 40px;
      border: 1px solid #707070;
      border-radius: 50%;
    }

    .description {
      color: #000;
      z-index: 300;
      text-align: center;
      @include font('body', 'regular');
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }

    &.not-editable {
      cursor: default;
      .overlay {
        opacity: 0.44;
      }
    }

    &.selected {
      background-color: $color-blue-2;

      .checkmark {
        background-color: $color-blue-1;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url("../../media/icons/checkmark.svg") center center no-repeat;
          width: 17px;
          height: 14px;
        }
      }
    }
  }
}
