@import '../../styles/utils/variables.scss';

.side {
  &-navigation,
  &-filters {
    margin-left: 1.5625rem;
  }

  &-nav {
    position: fixed;
    top: $headerHeight;
    right: 0;
    height: calc(100% - #{$headerHeight});

    @media only screen and (min-width: $lg) {
      display: none;
    }

    &--wrapper {
      background-color: #fff;
      margin: 0 -0.5rem;
      height: 100%;
      position: relative;
      z-index: 9999;
      border-right: 1px solid $color-gray-3;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    ul {
      margin-left: 1rem;
    }

    &--active {
      .side-nav--col {
        transform: translateX(0);
      }
    }

    &--col {
      width: $sideNavigationWidth !important;
      transform: translateX($sideNavigationWidth);

      z-index: 9999;
      transition: transform 0.25s ease-in-out;

      &.is-opened {
        transform: translateX(0);

        &:before {
          background-color: rgba(0, 0, 0, 0.6);
          position: fixed;
          width: 200vw;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
        }
      }

      @media only screen and (min-width: $lg) {
        transform: translateX(0);
      }
    }

    hr {
      height: 0.0625rem;
      margin: 0 auto;
      background-color: $color-gray-6;
    }

    &-button-close {
      width: 2.53125rem;
      height: 2.53125rem;
      position: absolute;
      top: 0;
      right: 0;

      @media only screen and (min-width: $lg) {
        display: none;
      }

      span {
        background-color: $color-white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-navigation {
    padding-top: 0.5rem;

    &__item {
      display: flex;
      position: relative;
      padding: 0.5rem 0 0.5rem 1.5625rem;
      margin-bottom: 16px;

      span {
        color: $color-black;
        font-size: 0.875rem;
        line-height: 1.25;
        @include font('body', 'regular');
      }

      &--active {
        span {
          color: $color-blue-3;
          @include font('body', 'medium');
        }
      }

      &--last {

      }

      .icon {
        font-size: 0.75rem;
        color: $color-blue-3;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    & ~ hr {
      margin-top: 2.5rem;
    }
  }

  &-filters {
    margin-right: 0;
    padding: 1.03125rem 0;
    list-style: none;

    li {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    &--title {
      font-size: 0.5625rem;
      color: $color-black;
      @include font('body', 'regular');
      margin-block-end: 1rem;
    }

    &__item {
      color: $color-white;
      font-size: 0.5625rem;
      padding: 0.21875rem 0.6875rem;
      border-radius: 3.125rem;

      &.bg-blue--lighter {
        color: $color-black;
      }
    }
  }
}
