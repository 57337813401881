@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@import "~react-toastify/scss/main";

@import "./styles/utils/gridlex.scss";
@import "./fonts/icons/style.css";
@import "./styles/index.scss";

.container {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: $headerHeight;

  @media only screen and (min-width: $sm) {
    margin-left: 0;
  }

  @media only screen and (min-width: $md) {
    margin-left: 0;
  }

  @media only screen and (min-width: $mlg) {
    margin-left: 0;
  }

  @media only screen and (min-width: $lg) {
    //margin-left: $sideNavigationWidth;
  }
}

[class*="--col"] {
  box-sizing: border-box;
  padding: 0 0.5rem;
  max-width: 100%;
}

.main-content {
  &--column {
    // width: calc(100% - $sideNavigationWidth);
    // padding: 0;
    overflow: hidden;

    @media only screen and (min-width: 64em) {
      width: calc(100% - 21.125rem);
      margin-left: 21.125rem;
    }
  }
}

.analytics-block {
  width: 100%;
}

.col-noBottom {
  width: 100%;
}

/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}
.Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;  // Default to full width
  padding: 0.8em 1.2em;
  overflow: hidden; // Or flex might break
  list-style: none;
  border: 3px solid transparent;
  > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
}

.fullpage-loading {
  width: 100%;
  height: calc(100vh - #{$headerHeight});
  position: relative;

  .fetching-data {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.ReactModal__Overlay {
  &--after-open {
    position: inherit !important;
    top: inherit !important;
    left: inherit !important;
    right: inherit !important;
    bottom: inherit !important;
  }

  &::before {
    background-color: rgba(102, 102, 102, 0.6) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: 9999;
    overflow-y: hidden;
  }
}

.ReactModal__Content {
  position: absolute;
  z-index: 10000;
}

/* Table column sizing
================================== */
.Rtable--2cols > .Rtable-cell  { width: 50%; }
.Rtable--3cols > .Rtable-cell  { width: 33.33%; }
.Rtable--4cols > .Rtable-cell  { width: 25%; }
.Rtable--5cols > .Rtable-cell  { width: 20%; }
.Rtable--6cols > .Rtable-cell  { width: 16.6%; }

.global-toast {
  width: 100%;
  margin: $headerHeight 0 0;
  top: 0;
  left: 0;
  padding: 0;

  &__toast {
    padding: 12px;
    min-height: unset;
    @include font('title', 'medium');
    color: #333 !important;
  }

  &__body {
    text-align: center;
    margin: 4px 0 0;

    a {
      text-decoration: underline;
    }
  }

  .Toastify__close-button {
    margin-top: 4px;
    color: #333;
  }
}
