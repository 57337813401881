.text-muted {
  color: #727272 !important; }

.text-underlined {
  text-decoration: underline; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-small {
  font-size: 0.867rem !important; }

.text-smaller {
  font-size: 0.8rem !important; }

.text-medium {
  font-size: 1.1rem !important; }

.text-regular {
  font-size: 1.2rem !important; }

.text-big {
  font-size: 1.4rem !important; }

.text-xl {
  font-size: 2.3rem !important; }

.text-semi-bold {
  font-weight: 600; }

.text-bold {
  font-weight: 700; }

.no-border {
  border: none !important; }

.no-margin {
  margin: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.padding-bottom--one {
  padding-bottom: 1rem !important; }

.padding-bottom--two {
  padding-bottom: 2rem !important; }

.padding-top--one {
  padding-top: 1rem !important; }

.padding-top--two {
  padding-top: 2rem !important; }

.padding-top--twentieth {
  padding-top: 0.2rem !important; }

.margin-left--one {
  margin-left: 1rem !important; }

.margin-left--two {
  margin-left: 2rem !important; }

.margin-top--half {
  margin-top: 0.5rem !important; }

.margin-top--one {
  margin-top: 1rem !important; }

.margin-top--one-and-half {
  margin-top: 1.5rem !important; }

.margin-top--two {
  margin-top: 2rem !important; }

.margin-top--two-and-half {
  margin-top: 2.5rem !important; }

.margin-top--three {
  margin-top: 3rem !important; }

.margin-bottom--none {
  margin-bottom: 0 !important; }

.margin-bottom--one {
  margin-bottom: 1rem !important; }

.margin-bottom--one-and-half {
  margin-bottom: 1.5rem !important; }

.margin-bottom--two {
  margin-bottom: 2rem !important; }

.margin-bottom--two-and-half {
  margin-bottom: 2.5rem !important; }

.margin-bottom--three {
  margin-bottom: 3rem !important; }

.margin-bottom--four {
  margin-bottom: 4rem !important; }

.margin-right--one {
  margin-right: 1rem !important; }

.margin-right--two {
  margin-right: 2rem !important; }

.margin-x-auto {
  margin: 0 auto !important; }

[hidden],
.hidden {
  display: none !important; }

.flex-align--left {
  display: flex;
  margin-left: auto;
  align-self: flex-start; }

.flex-align--center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flex-align--right {
  display: flex;
  margin-left: auto;
  align-self: flex-end; }

.required-fail {
  border-color: #E14842 !important; }

.form-disclaimer {
  font-size: 0.75rem;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: 1.25;
  color: #6F7A82;
  margin-bottom: 1rem; }

.overflow-hidden {
  overflow: hidden !important; }

.half-width {
  width: 50%; }

.bg-blue {
  background-color: #4062AE; }
  .bg-blue--light {
    background-color: #5B78B8; }
  .bg-blue--lighter {
    background-color: #B7CDFF; }

.header-nav {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  background-color: #4062AE;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }
  .header-nav--logo {
    max-width: 6.125rem;
    max-height: 4.0625rem;
    display: inline-block; }
    .header-nav--logo img {
      width: 100%; }
  .header-nav nav {
    margin-left: auto; }
    .header-nav nav a {
      font-smooth: always;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-style: normal;
      line-height: 1.25;
      color: #B7CDFF;
      margin-left: 12px;
      margin-right: 12px; }
    .header-nav nav .nav__link--active {
      color: #FFF; }
  .header-nav--desktop {
    display: none; }
    @media only screen and (min-width: 576px) {
      .header-nav--desktop {
        display: flex;
        width: 100%; } }
    .header-nav--desktop .button-signin {
      margin-left: 32px;
      border-radius: 20px;
      font-smooth: always;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-style: normal;
      line-height: 1.25;
      font-size: 14px; }
    .header-nav--desktop .user-badge {
      margin-left: 32px; }
  .header-nav--devices {
    width: 100%;
    display: flex; }
    @media only screen and (min-width: 576px) {
      .header-nav--devices {
        display: none; } }
