/**
 * http://meyerweb.com/eric/tools/css/reset/
 * v2.0 | 20110126
 * License: none (public domain)
 */
@import url(../../fonts/icons/style.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

[class^="col-"] {
  padding-bottom: 0; }

.text-muted {
  color: #727272 !important; }

.text-underlined {
  text-decoration: underline; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-small {
  font-size: 0.867rem !important; }

.text-smaller {
  font-size: 0.8rem !important; }

.text-medium {
  font-size: 1.1rem !important; }

.text-regular {
  font-size: 1.2rem !important; }

.text-big {
  font-size: 1.4rem !important; }

.text-xl {
  font-size: 2.3rem !important; }

.text-semi-bold {
  font-weight: 600; }

.text-bold {
  font-weight: 700; }

.no-border {
  border: none !important; }

.no-margin {
  margin: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.padding-bottom--one {
  padding-bottom: 1rem !important; }

.padding-bottom--two {
  padding-bottom: 2rem !important; }

.padding-top--one {
  padding-top: 1rem !important; }

.padding-top--two {
  padding-top: 2rem !important; }

.padding-top--twentieth {
  padding-top: 0.2rem !important; }

.margin-left--one {
  margin-left: 1rem !important; }

.margin-left--two {
  margin-left: 2rem !important; }

.margin-top--half {
  margin-top: 0.5rem !important; }

.margin-top--one {
  margin-top: 1rem !important; }

.margin-top--one-and-half {
  margin-top: 1.5rem !important; }

.margin-top--two {
  margin-top: 2rem !important; }

.margin-top--two-and-half {
  margin-top: 2.5rem !important; }

.margin-top--three {
  margin-top: 3rem !important; }

.margin-bottom--none {
  margin-bottom: 0 !important; }

.margin-bottom--one {
  margin-bottom: 1rem !important; }

.margin-bottom--one-and-half {
  margin-bottom: 1.5rem !important; }

.margin-bottom--two {
  margin-bottom: 2rem !important; }

.margin-bottom--two-and-half {
  margin-bottom: 2.5rem !important; }

.margin-bottom--three {
  margin-bottom: 3rem !important; }

.margin-bottom--four {
  margin-bottom: 4rem !important; }

.margin-right--one {
  margin-right: 1rem !important; }

.margin-right--two {
  margin-right: 2rem !important; }

.margin-x-auto {
  margin: 0 auto !important; }

[hidden],
.hidden {
  display: none !important; }

.flex-align--left {
  display: flex;
  margin-left: auto;
  align-self: flex-start; }

.flex-align--center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flex-align--right {
  display: flex;
  margin-left: auto;
  align-self: flex-end; }

.required-fail {
  border-color: #E14842 !important; }

.form-disclaimer {
  font-size: 0.75rem;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: 1.25;
  color: #6F7A82;
  margin-bottom: 1rem; }

.overflow-hidden {
  overflow: hidden !important; }

.half-width {
  width: 50%; }

*,
*:before,
*:after {
  box-sizing: border-box; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

html {
  min-height: 100vh; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html ::-moz-selection,
body ::-moz-selection {
  background: #F0E7E0; }

html ::selection,
body ::selection {
  background: #F0E7E0; }

template {
  display: none; }

main {
  display: block;
  width: 100%; }

footer {
  width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  font-weight: 400; }

h1 {
  font-size: 6rem; }

h2 {
  font-size: 4rem; }

h3 {
  font-size: 3.2rem; }

h4 {
  font-size: 2.4rem; }

h5 {
  font-size: 1.6rem; }

h6 {
  font-size: 1.2rem; }

a {
  color: inherit;
  text-decoration: inherit; }

small {
  display: inline-block;
  font-size: 0.867rem; }

button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer; }

button:hover, button:active, button:focus {
  outline: 0; }

input[type=checkbox] {
  margin: 0; }

hr {
  height: 0.2rem;
  margin: 1.53rem 0;
  border: none;
  background-color: transparent; }

table {
  width: 100%;
  height: auto; }

p {
  line-height: 1.6rem; }

li {
  list-style: none; }

figure {
  background-repeat: no-repeat;
  background-position: center center; }

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #4062AE;
  opacity: 1; }

:focus {
  outline: none; }

:required {
  box-shadow: none; }

::-moz-focus-inner {
  border: 0; }

input[type=number] {
  -moz-appearance: textfield !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

.bg-blue {
  background-color: #4062AE; }
  .bg-blue--light {
    background-color: #5B78B8; }
  .bg-blue--lighter {
    background-color: #B7CDFF; }

.button {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.25;
  min-width: 6.875rem;
  padding: 0.375em 1.6875em;
  position: relative; }
  .button-primary {
    color: #FFFFFF;
    background-color: #4062AE; }
  .button-secondary {
    color: #4062AE;
    background-color: #FFFFFF; }
  .button-gray {
    border: 1px solid #C4C4C4;
    background-color: #DFDFDF; }
  .button-dark-blue {
    color: #FFFFFF;
    background-color: #182E5E; }
  .button-round {
    border-radius: 3.125rem; }
    .button-rounded {
      border-radius: 0.25rem; }
  .button-tab {
    font-size: 1rem;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    line-height: 1.25;
    text-align: right;
    color: #6F7A82;
    padding: 1rem 1.25rem; }
    .button-tab--active {
      background-color: #F8F8F8; }
  .button-burguer {
    width: 2.5rem;
    min-width: inherit;
    height: 2.5rem;
    padding: 0;
    position: relative; }
    .button-burguer span {
      width: 100%;
      height: 0.125rem;
      display: block;
      background-color: #fff;
      position: absolute;
      right: 0; }
      .button-burguer span:not(:first-of-type):not(:last-of-type) {
        width: 75%; }
      .button-burguer span:first-of-type {
        top: 0.4375rem; }
      .button-burguer span:last-of-type {
        bottom: 0.4375rem; }
  .button-quick-search {
    font-size: 2rem;
    width: 2.5rem;
    min-width: inherit;
    height: 2.5rem;
    padding: 0; }
    .button-quick-search .icon {
      transform: translate(-50%, -50%); }
  .button-login {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: #fff;
    background-color: #182E5E; }
  .button-ghost {
    background-color: transparent !important;
    box-shadow: none !important; }
  .button-social {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding: 0.8125em 1em;
    padding-left: 3.375em;
    margin-bottom: 1.6875rem; }
    .button-social .icon {
      left: 0.7rem; }
    .button-social--google {
      color: #464646;
      background-color: #FFFFFF; }
      .button-social--google img {
        width: 1.875em;
        height: 1.875em;
        display: block; }
    .button-social--facebook {
      color: #FFFFFF;
      background-color: #4267B2; }
      .button-social--facebook .icon {
        font-size: 1.5em; }
  .button-icon--left {
    padding-left: 2.4375rem; }
    .button-icon--left .icon {
      left: 0.625rem; }
  .button-icon--right {
    padding-right: 2.4375rem; }
    .button-icon--right .icon {
      right: 0.625rem; }
  .button .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

.InterestsModal {
  background-color: #FFF; }
  .InterestsModal.ReactModal__Content--after-open {
    position: fixed !important;
    top: 55% !important;
    left: 50% !important;
    bottom: inherit !important;
    transform: translate(-50%, -50%);
    border-radius: 0.375rem !important;
    padding: 0 !important;
    border: 0 !important;
    width: 90%; }
    @media only screen and (min-width: 1024px) {
      .InterestsModal.ReactModal__Content--after-open {
        width: 75%; } }
    @media only screen and (max-width: 800px) {
      .InterestsModal.ReactModal__Content--after-open {
        position: absolute !important; } }
  .InterestsModal.ReactModal__Content form {
    position: relative; }
  .InterestsModal.ReactModal__Content input:last-of-type {
    margin-bottom: 1rem; }
  .InterestsModal.ReactModal__Content .button {
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16); }
    .InterestsModal.ReactModal__Content .button-rcpwd {
      padding: 0.375em;
      margin-bottom: 0;
      position: relative;
      bottom: 1.0625rem;
      right: 0; }
    .InterestsModal.ReactModal__Content .button-crtcc {
      padding: 0.375em;
      margin-bottom: 0; }
    .InterestsModal.ReactModal__Content .button-login {
      padding: 0.8125em 1em; }
  .InterestsModal--header {
    background-color: #4062AE; }
    .InterestsModal--header img {
      display: block;
      max-width: 11.3125rem;
      margin: 0 auto; }
  .InterestsModal--body {
    padding: 1.4375rem 2.25rem 1.3125rem;
    display: flex;
    flex-direction: column; }
    .InterestsModal--body .interests-list {
      justify-content: center;
      margin-bottom: 2.3125rem; }
    .InterestsModal--body button {
      align-self: center; }
  .InterestsModal--title {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    font-size: 1.25rem;
    letter-spacing: 0.01em;
    color: #464646;
    margin-bottom: 1.3125rem; }
  .InterestsModal--subtitle {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.25;
    font-size: 1rem;
    letter-spacing: 0.01em;
    color: #464646;
    margin-bottom: 2.3125rem; }
