@charset "UTF-8";
.user-badge__avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 3px solid #FFF;
  position: relative;
  margin-left: 12px;
  margin-right: 16px;
  padding-right: 16px; }
  .user-badge__avatar img {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .user-badge__avatar:after {
    display: block;
    content: '▼';
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    color: #FFF; }

.user-badge__dropdown {
  background-color: #FFF;
  padding: 16px;
  position: absolute;
  right: 8px;
  top: 69px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: 200ms ease;
  -moz-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .user-badge__dropdown hr {
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: -16px;
    margin-right: -16px;
    height: 1px;
    background-color: #c1c1c1; }
  .user-badge__dropdown li {
    padding: 6px;
    margin-bottom: 6px;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25; }
    .user-badge__dropdown li:first-of-type {
      margin-top: -6px; }
    .user-badge__dropdown li:last-of-type {
      margin-bottom: -6px; }
  .user-badge__dropdown.is-opened {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100); }
